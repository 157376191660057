body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #1b1c1d;
}
img {
  max-width: 100%;
}
.remove-border-menu-item:before {
  background: transparent !important;
}
.remove-border-menu-item:after {
  background: transparent !important;
}
.react-datepicker-wrapper,
.react-datepicker-wrapper .react-datepicker__input-container {
  width: 100%;
}

.category-section {
  margin-top: 40px;
}
div.category-section:first-child {
  margin-top: 0px;
}
.last-divider:last-child {
  display: none;
}
.section-item {
  margin: 10px 0px;
  font-size: 18px;
}
.section-item.active {
  background: rgba(0, 0, 0, 0.3);
}
.section-item a {
  color: #777;
}
.section-item:hover a {
  color: #000;
}
div.section-item:first-child {
  margin-top: 0px;
}
div.account-details-left-column {
  font-weight: bold;
  width: 40%;
  display: inline-block;
  vertical-align: top;
}
div.account-details-right-column {
  display: inline-block;
  width: 60%;
}

@media only screen and (max-width: 768px) {
  body {
    margin-top: 0px;
  }
}
